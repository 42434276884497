import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthUserModel } from '../../../pages/main/config/models/auth-user.model';
import jwt_decode from 'jwt-decode';
import { HttpClient } from '@angular/common/http';
import { ApiAuthUserService } from './api-auth-user.service';
import { ApiPermissionService } from './api-permission.service';
import { SNACK_BAR_CLASS } from '../../constants/vocabulary';
import { ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot } from '@angular/router';
import { SnackBarService } from '../../../pages/main/services/snack-bar.service';
import { USER_DATA_CONFIG } from '../../../pages/main/config/user-data.config';

@Injectable()
export class AuthUserService implements Resolve<void> {
  private userData: AuthUserModel = Object.assign({}, USER_DATA_CONFIG);
  private userSubj: BehaviorSubject<AuthUserModel> = new BehaviorSubject(this.userData);
  public userInfo$: Observable<AuthUserModel> = this.userSubj.asObservable();

  constructor(
    private http: HttpClient,
    private apiAuthUserService: ApiAuthUserService,
    private ps: ApiPermissionService,
    private router: Router,
    private sb: SnackBarService,
  ) {
  }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<any>|Promise<any>|any {
    this.setUserInfo();
  }

  public setUserInfo(): void {
    const token = this.getTokenFromLocalStorage();
    if (!!token) {
      this.setUserData();
      this.ps.setRoles();
    }
  }

  public cleanUserInfo(): void {
    this.userData = Object.assign({}, USER_DATA_CONFIG);
    this.userSubj.next(this.userData);
    this.ps.removePermissions();
  }

  private getTokenFromLocalStorage(): string | undefined {
    const token = localStorage.getItem('token');
    if (!!token) {
      const decoded: any = jwt_decode(localStorage.getItem('token'));
      this.userData.id = decoded.userId;
    }
    return token;
  }

  private setUserData(): void {
    this.apiAuthUserService.getUserData(this.userData.id)
      .subscribe(([details, permissions]) => {
          this.userData.permissions = permissions;
          this.userData.name = details.firstName;
          this.userData.lastName = details.lastName;
          this.userData.roles = details.roles;
          this.userSubj.next(this.userData);
          this.setPermissions();
        },
        error => {
          if (error.status === 403) {
            this.sb.open('You don\'t have permission to access', SNACK_BAR_CLASS.ERROR);
          }
        });
  }

  private setPermissions(): void {
    this.ps.setPermissions(this.userData.permissions);
  }
}
