import { RoleModel } from './role.model';
import { IUser } from '../interfaces/user.interface';
import { IRole } from '../interfaces/role.interface';

export class UserModel {
  readonly id: number;
  readonly firstName: string;
  readonly lastName: string;
  readonly email: string;
  readonly phone: string;
  readonly isDeleted: boolean;
  readonly resetPassword: boolean;
  readonly lastAccessDate: string;
  readonly lastAccessIP: string;
  readonly createDate: string;
  readonly updateDate: string;
  readonly roles: RoleModel[];

  constructor(input: IUser) {
    this.id = input.id;
    this.firstName = input.firstName;
    this.lastName = input.lastName;
    this.email = input.email;
    this.phone = input.phone;
    this.isDeleted = input.isDeleted;
    this.resetPassword = input.resetPassword;
    this.lastAccessDate = input.lastAccessDate;
    this.lastAccessIP = input.lastAccessIP;
    this.createDate = input.createDate;
    this.updateDate = input.updateDate;
    this.roles = input.roles.map((role: IRole) => new RoleModel(role));
  }
}
