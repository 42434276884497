export type VocabularyKeyType = { [key: string]: string };

export const ERRORS_TEXT: VocabularyKeyType = {
  DEFAULT: 'ErrorMessage',
  BAD_REQUEST: 'Oops! Something went wrong...',
  NO_SUCH_PLAYER_ID: 'No such player ID',
  INVALID_USER_PASSWORD: 'Invalid username or password',
  NOT_AUTHORIZED: 'Not authorized',
  ERROR_500: 'Internal Server Error!',
  FILE_SIZE: 'File size is too large',
  UPLOAD_FAILED: 'Could not upload the file!',
  WEAK_PASSWORD: 'Use at least 6 charsets, must contain at least one digit, capital and small character',
};

export const SNACK_BAR_CLASS: VocabularyKeyType = {
  ERROR: 'error-form-bar',
  DONE: 'update-form-bar',
  CANCEL: 'cancel-form-bar',
};

export const SNACK_BAR_MSG: VocabularyKeyType = {
  CANCEL: 'Canceled',
  UPDATED: 'Updated',
  IS_UPDATED: 'updated',
  BULK_OPERATORS_UPDATED: 'operators updated',
  BULK_BRANDS_UPDATED: 'brands updated',
  LOG_OUT: 'Log Out',
  CHANGE_PASSWORD: 'Change your password',
  CHANGE_PASSWORD_DONE: 'Your password changed! Login with a new password',
  NEW_USER_CREATED: 'New user created!',
  NEW_BRAND_CREATED: 'New brand created!',
  COPIED_TO_CLIPBOARD: 'Copied to clipboard',
  REFRESH_SECRET_TOKEN: 'Secret Token is refreshed',
  FILE_UPLOADED: 'File uploaded successfully',
};

export const PAGE_TITLE: VocabularyKeyType = {
  OPERATORS: 'Operators',
  PLAYERS: 'Players',
  PLAYER_GROUPS: 'Player groups',
  PLAYER_DETAILS: 'General details',
  PLAYER_HISTORY: 'Bet History',
  SEARCH_PLAYER: 'Search for Players',
  INDIVIDUAL_PLAYER: 'Individual player',
  CURRENCIES: 'Currencies',
  USERS_PANEL: 'Users',
  OPERATORS_PANEL: 'Operators',
  BRANDS_PANEL: 'Brands',
  GAMES: 'Games',
  PLAYER_MANAGEMENT_PANEL: 'Player Management',
  PLAYER_MANAGEMENT_ITEM_ONE: 'Search for Players',
  PLAYER_MANAGEMENT_ITEM_TWO: 'Search for Bets',
  ANALYTICS_PANEL: 'Report and Analytics',
  ANALYTICS_ITEM_ONE: 'Game performance',
  ANALYTICS_ITEM_TWO: 'Monthly performance',
  ANALYTICS_ITEM_THREE: 'Operator performance',
  GAMES_PANEL: 'Games',
  TEST_USERS: 'Test users',
  GAME_ITEM_ONE: 'Game list',
  GAME_ITEM_TWO: 'Marketing materials',
  GAME_ITEM_THREE: 'Operations game view',
  ADMIN_PANEL: 'Admin Panel',
  ADMIN_ITEM_ONE: 'Admin settings',
  ADMIN_ITEM_TWO: 'User activity',
  NFT: 'NFT',
  NFT_LIST: 'NFT List',
  NFT_CONFIGURATION: 'NFT Configuration'
};

export const MENU_PATH: VocabularyKeyType = {
  LOGIN: 'login',
  CHANGE: 'change',
  MAIN: 'main',
  GAMES: 'games',
  GAME: 'game',
  OPERATORS: 'operators',
  PLAYERS: 'players',
  PLAYER_GROUPS: 'player-groups',
  SEARCH_PLAYER: 'search',
  PLAYER_INFO: 'info',
  PLAYER_DETAILS: 'details',
  PLAYER_HISTORY: 'history',
  INDIVIDUAL_PLAYER: 'individual-player',
  CREATE_GROUP: 'create-group',
  SEARCH_BET: 'search-bet',
  CURRENCY: 'currencies',
  MARKETING: 'marketing',
  ANALYTICS_DASHBOARD: 'dashboard',
  ANALYTICS_BRANDS: 'brands',
  // ANALYTICS_ITEM_TWO: 'monthly-performance',
  ANALYTICS_ITEM_ONE: 'game-performance',
  // ANALYTICS_ITEM_THREE: 'operator-performance',
  ADMIN_PANEL: 'admin-panel',
  USERS_PANEL: 'users',
  OPERATORS_PANEL: 'operators',
  BRANDS_PANEL: 'brands',
  GAMES_PANEL: 'games',
  TEST_USERS: 'test-users',
  USER_ACTIVITY: 'user-activity',
  GAME_VIEW: 'games-view',
  NOT_IMPLEMENTED: 'not-implemented',
  EDIT_BRAND: 'edit-brand',
  EDIT_OPERATOR: 'edit-operator',
  NFT_LIST: 'nft-list',
  NFT_CONFIGURATION: 'nft-configuration',
};

export const TOOLTIP_MSG: VocabularyKeyType = {
  FILE_SIZE_LIMIT: 'Max file size - 1 GB',
  THUMBNAIL_SIZE_LIMIT: 'Max thumbnail size - 50 MB',
  CSV_SIZE_LIMIT: 'Max file size - 10 MB',
};
