import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable(
  { providedIn: 'root' },
)
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {
  }

  public open(barMessage: string, barClass: string): void {
    this.snackBar.open(barMessage, '', {
      duration: 3000,
      horizontalPosition: 'end',
      verticalPosition: 'top',
      panelClass: barClass,
    });
  }
}
