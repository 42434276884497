import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppService } from './app.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { AuthInterceptor } from './shared/interceptors/auth.interceptor';
import { HttpErrorInterceptor } from './shared/interceptors/http-error.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AuthGuard } from './shared/api/guards/auth.guard';
import { CheckTokenGuard } from './shared/api/guards/check-token.guard';
import { AuthComponent } from './pages/auth/auth.component';
import { AuthModule } from './pages/auth/auth.module';
import { ApiAuthService } from './shared/api/services/api-auth.service';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ApiPermissionService } from './shared/api/services/api-permission.service';
import { AuthUserService } from './shared/api/services/auth-user.service';
import { ApiAuthUserService } from './shared/api/services/api-auth-user.service';

@NgModule({
  declarations: [AppComponent, AuthComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    AuthModule,
    MatSnackBarModule,
    NgxPermissionsModule.forRoot(),
  ],
  providers: [
    AppService,
    ApiAuthService,
    AuthGuard,
    CheckTokenGuard,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
    ApiPermissionService,
    AuthUserService,
    ApiAuthUserService,
  ],
  bootstrap: [AppComponent],
})

export class AppModule {
}
