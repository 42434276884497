import { Injectable } from '@angular/core';
import { forkJoin, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../../constants/urls';
import { map } from 'rxjs/operators';
import { IUser } from '../../../pages/main/config/interfaces/user.interface';
import { UserModel } from '../../../pages/main/config/models/user.model';
import { IApiResp } from '../dto/response/interfaces/api-resp.interface';

@Injectable()
export class ApiAuthUserService {
  constructor(
    private http: HttpClient,
  ) {
  }

  public getUserData(id: number): Observable<[UserModel, string[]]> {
    const details = this.getUserDetail(id);
    const permissions = this.getUserPermissions();
    return forkJoin<UserModel, string[]>([details, permissions]);
  }

  private getUserDetail(id: number): Observable<UserModel> {
    return this.http.get(API_URLS.users.getById(id))
      .pipe(
        map((res: IApiResp<IUser>) => {
          return new UserModel(res.data);
        }),
      );
  }

  private getUserPermissions(): Observable<string[]> {
    return this.http.get(API_URLS.roles.permissions)
      .pipe(
        map((res: IApiResp<string[]>) => {
          return res.data;
        }),
      );
  }
}
