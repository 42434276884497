import { IRefreshPassword } from '../interfaces/refresh-password.interface';

export class RefreshPasswordDto {
  readonly email: string;
  readonly currentPassword: string;
  readonly newPassword: string;

  constructor(input: IRefreshPassword) {
    this.email = input.email;
    this.currentPassword = input.password;
    this.newPassword = input.newPassword;
  }
}
