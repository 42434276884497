import { UserSignInDataModel } from '../../../../../pages/main/config/models/user-sign-in-data.model';
import { IUserResp } from '../interfaces/user-resp.interface';


export class ApiUserRespModel {
  readonly token: string;
  readonly user: UserSignInDataModel;

  constructor(input: IUserResp) {
    this.token = input.token;
    this.user = input.user;
  }
}
