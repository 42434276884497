import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { SnackBarService } from '../../pages/main/services/snack-bar.service';
import { ERRORS_TEXT, SNACK_BAR_CLASS } from '../constants/vocabulary';
import { ApiAuthService } from '../api/services/api-auth.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    private apiLoginService: ApiAuthService,
    private snackBarService: SnackBarService,
  ) {
  }

  public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(catchError(err => {
      this.checkError(err);
      return throwError(err);
    }));
  }

  private checkError(err: HttpErrorResponse): void {
    if (err.status === 401) {
      this.snackBarService.open(ERRORS_TEXT.NOT_AUTHORIZED, SNACK_BAR_CLASS.ERROR);
      this.apiLoginService.logOut();
    }
  }
}
