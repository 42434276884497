import { Injectable } from '@angular/core';
import { ApiAuthService } from '../../shared/api/services/api-auth.service';
import { Observable } from 'rxjs';
import { RefreshPasswordDto } from '../../shared/api/dto/request/models/refresh-password.dto';
import { UserGetTokenDto } from '../../shared/api/dto/request/models/user-get-token.dto';
import { ApiUserRespModel } from '../../shared/api/dto/response/models/api-user-resp.model';
import { IApiResp } from '../../shared/api/dto/response/interfaces/api-resp.interface';

@Injectable()
export class AuthService {
  constructor(
    private apiAuthService: ApiAuthService,
  ) { }
  public logIn(userData: UserGetTokenDto): Observable<ApiUserRespModel> {
    return this.apiAuthService.logIn(userData);
  }

  public refreshPassword(data: RefreshPasswordDto): Observable<IApiResp<string>> {
    return this.apiAuthService.refreshPassword(data);
  }
}
