import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignInComponent } from './sign-in/sign-in.component';
import { MatInputModule } from '@angular/material/input';
import { ReactiveFormsModule } from '@angular/forms';
import { RefreshPasswordComponent } from './refresh-password/refresh-password.component';
import { AuthService } from './auth.service';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [
    SignInComponent,
    RefreshPasswordComponent,
  ],
  imports: [
    CommonModule,
    MatInputModule,
    ReactiveFormsModule,
    MatIconModule,
  ],
  providers: [
    AuthService,
  ],
  exports: [
    SignInComponent,
    RefreshPasswordComponent,
  ]
})
export class AuthModule { }
