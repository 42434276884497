import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';

import { Observable } from 'rxjs';
import { ApiAuthService } from '../services/api-auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private apiLoginService: ApiAuthService,
    private router: Router,
  ) {
  }

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean| UrlTree>
    | Promise<boolean | UrlTree>
    | boolean | UrlTree {
    const checkToken = this.apiLoginService.checkToken();
    if (checkToken) {
      this.router.navigateByUrl('/main');
      return false;
    }
    return true;
  }
}
