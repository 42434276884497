import { Component, OnInit } from '@angular/core';
import { AuthUserService } from './shared/api/services/auth-user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent implements OnInit{
  public title = 'backoffice';

  constructor(
    private authUserService: AuthUserService,
  ) {
  }

  public ngOnInit(): void {
    this.authUserService.setUserInfo();
  }
}
