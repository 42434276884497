import { IUserGetToken } from '../interfaces/user-get-token.interface';

export class UserGetTokenDto {
  readonly email: string;
  readonly password: string;

  constructor(input: IUserGetToken) {
    this.email = input.email;
    this.password = input.password;
  }
}
