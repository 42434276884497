import { IRole } from '../interfaces/role.interface';

export class RoleModel {
  readonly id: number;
  readonly name: string;
  readonly description: string;

  constructor(input: IRole) {
    this.id = input.id;
    this.name = input.name;
    this.description = input.description;
  }
}
