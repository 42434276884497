import { Injectable } from '@angular/core';
import { UserGetTokenDto } from '../dto/request/models/user-get-token.dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { API_URLS } from '../../constants/urls';
import { map } from 'rxjs/operators';
import { Router } from '@angular/router';
import { RefreshPasswordDto } from '../dto/request/models/refresh-password.dto';
import { IApiResp } from '../dto/response/interfaces/api-resp.interface';
import { AuthUserService } from './auth-user.service';
import { ApiUserRespModel } from '../dto/response/models/api-user-resp.model';
import { IUserResp } from '../dto/response/interfaces/user-resp.interface';

@Injectable()
export class ApiAuthService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private authUserService: AuthUserService,
  ) {
  }

  public checkToken(): boolean {
    return !!localStorage.getItem('token');
  }

  public logIn(userData: UserGetTokenDto): Observable<ApiUserRespModel> {
    return this.http.post(API_URLS.auth.login, userData)
      .pipe(
        map((user: IApiResp<IUserResp>) => {
          if (user.success) {
            return new ApiUserRespModel(user.data);
          }
        }),
      );
  }

  public logOut(): void {
    localStorage.removeItem('token');
    this.authUserService.cleanUserInfo();
    this.router.navigateByUrl('/');
  }

  public refreshPassword(data: RefreshPasswordDto): Observable<IApiResp<string>> {
    return this.http.post<IApiResp<string>>(API_URLS.auth.refreshPassword, data);
  }
}
