<div class="form-wrapper">
  <h2>Refresh Password</h2>
  <form [formGroup]="changePasswordForm">
    <div class="container">
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Enter your new password</mat-label>
            <input matInput formControlName="newPassword" [type]="passwordHide ? 'password' : 'text'">
            <mat-icon class="eye" matSuffix (click)="passwordHide = !passwordHide">{{passwordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <mat-form-field class="full-width">
            <mat-label>Confirm your Password</mat-label>
            <input matInput formControlName="confirmNewPassword" [type]="confirmPasswordHide ? 'password' : 'text'">
            <mat-icon class="eye" matSuffix (click)="confirmPasswordHide = !confirmPasswordHide">{{confirmPasswordHide ? 'visibility_off' : 'visibility'}}</mat-icon>
          </mat-form-field>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <button class="btn btn-primary btn-block"
                  (click)="confirmPassword()"
                  [disabled]="checkPassword() || changePasswordForm.invalid"
                  type="button">
            Confirm password
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
