import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MENU_PATH } from '../../constants/vocabulary';
import { ApiAuthService } from '../services/api-auth.service';
import { AVAILABLE_GUEST_PATH } from '../../../pages/main/config/available-guest-path.config';

@Injectable()
export class CheckTokenGuard implements CanActivate {

  private availableGuestPath: string[] = AVAILABLE_GUEST_PATH;

  constructor(
    private apiLoginService: ApiAuthService,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean | UrlTree {
    const checkToken = this.apiLoginService.checkToken();
    const includesPath = this.availableGuestPath.includes(window.location.pathname);
    if (!checkToken && !includesPath) {
      this.router.navigateByUrl(`/${MENU_PATH.LOGIN}`);
      return false;
    }
    return true;
  }
}
